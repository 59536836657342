import React, { FC, ReactNode } from 'react';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Text, TextTypography as TYPOGRAPHY } from 'wix-ui-tpa';
import stylesParams from '../../../../../stylesParams';
import { classes, st } from './Details.st.css';
import { DataHooks } from './consts';
import { useWidgetViewModel } from '../../../../hooks/useWidgetViewModel';
import settingsParams from '../../../../../settingsParams';

export type DetailsProps = {
  children: ReactNode;
  ellipsis?: boolean;
  ariaHidden?: boolean;
  type?: string;
};

export const Details: FC<DetailsProps> = (props) => {
  const { children, type, ariaHidden, ellipsis } = props;
  const { isMobile } = useEnvironment();
  const styles = useStyles();
  const settings = useSettings();
  const { shouldWorkWithAppSettings } = useWidgetViewModel();
  const tagName = shouldWorkWithAppSettings
    ? styles.get(stylesParams.serviceDetailsFont).htmlTag
    : settings.get(settingsParams.serviceDetailsFontHtmlTag);

  return (
    <Text
      data-type={type}
      data-hook={DataHooks.ROOT}
      className={st(classes.root, {
        isMobile,
        ellipsis: ellipsis || false,
      })}
      aria-hidden={ariaHidden || false}
      typography={TYPOGRAPHY.listText}
      tagName={tagName}
    >
      {children}
    </Text>
  );
};
